.AssetImageContainer {
    position: relative;
}

.SelectedAsset {
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 20px;
}
